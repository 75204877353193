export const DEFAULT_CURRENCY = 'USD';
export const DEFAULT_LOCALE = 'en-US';

export const parseCurrency = (
  currency: string | number | undefined | null,
): number | null | undefined => {
  if (currency === null) {
    return null;
  }
  if (currency === undefined) {
    return undefined;
  }

  if (typeof currency === 'number') {
    return currency;
  }

  return parseFloat(currency);
};

/**
 * Return the formatted amount given the currency and country. The country will determine the locale used for
 * formatting (e.g., £100.00 vs. £ 100,00). The default country is "US" (mainly used for Stipends (AllotmentCycles).
 *
 * @param amount the amount to format
 * @param currencyCode the three letter currency code
 * @param maximumFractionDigits
 * @returns the formatted currency
 */
export function formatCurrency(
  amount: string | number,
  currencyCode: string,
  maximumFractionDigits?: number,
) {
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }

  const locale = navigator.language;
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    maximumFractionDigits,
  }).format(amount);
}

export function getCurrencySymbol(currencyCode: string, locale = null) {
  try {
    return new Intl.NumberFormat(locale || DEFAULT_LOCALE, {
      style: 'currency',
      currency: currencyCode,
    })
      .format(0)
      .replace(/[\d., ]/g, '');
  } catch (e) {
    console.error('Error getting currency symbol', e);
  }

  return null;
}

/**
 * Returns the formatted amount given the country.
 * @see formatCurrency
 * @param amount the numerical amount of money to format
 * @param countryCode the given country code that contains the country default name, currency and locale
 * @param givenCurrency an optional parameter to explicitly define the currency to use
 * @param maximumFractionDigits
 */
export function formatCurrencyFromCountryCode(
  amount: string | number,
  countryCode: SupportedCountriesType | undefined,
  givenCurrency?: string,
  maximumFractionDigits = undefined,
) {
  if (!countryCode) {
    countryCode = 'US';
  }

  const country = getSupportedCountryInfo(countryCode);
  const currency = country ? country.currency : DEFAULT_CURRENCY;

  return formatCurrency(amount, givenCurrency ?? currency, maximumFractionDigits);
}

export function formatCurrencySymbolFromCountryCode(
  countryCode?: SupportedCountriesType,
  givenCurrency?: SupportedCountriesType,
) {
  const locale = navigator.language;
  const country = SUPPORTED_COUNTRIES[countryCode || 'US'];
  const currency = country && country.currency ? country.currency : DEFAULT_CURRENCY;

  const currencyPart = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: givenCurrency ?? currency,
  })
    .formatToParts()
    .find((part) => part.type === 'currency');

  if (currencyPart) {
    return currencyPart.value;
  } else {
    throw new Error('Currency part not found in formatted parts.');
  }
}

export function getCurrencyName(currencyCode: string, locales = []) {
  return new Intl.DisplayNames(locales || [], {type: 'currency'}).of(currencyCode);
}

export const getSupportedCountryInfo = (countryCode: SupportedCountriesType) =>
  SUPPORTED_COUNTRIES[countryCode];

export type SupportedCountriesType = keyof typeof SUPPORTED_COUNTRIES;

const SUPPORTED_COUNTRIES = {
  US: {
    name: 'United States',
    currency: 'USD',
    locale: 'en_US',
  },
  AR: {
    name: 'Argentina',
    currency: 'ARS',
    locale: 'es_AR',
  },
  AT: {
    name: 'Austria',
    currency: 'EUR',
    locale: 'de_AT',
  },
  AU: {
    name: 'Australia',
    currency: 'AUD',
    locale: 'en_AU',
  },
  BE: {
    name: 'Belgium',
    currency: 'EUR',
    locale: 'nl_BE',
  },
  BG: {
    name: 'Bulgaria',
    currency: 'BGN',
    locale: 'bg_BG',
  },
  CA: {
    name: 'Canada',
    currency: 'CAD',
    locale: 'en_CA',
  },
  CR: {
    name: 'Costa Rica',
    currency: 'CRC',
    locale: 'es_CR',
  },
  CY: {
    name: 'Cyprus',
    currency: 'EUR',
    locale: 'el_CY',
  },
  DE: {
    name: 'Germany',
    currency: 'EUR',
    locale: 'de_DE',
  },
  ES: {
    name: 'Spain',
    currency: 'EUR',
    locale: 'es_ES',
  },
  DK: {
    name: 'Denmark',
    currency: 'DKK',
    locale: 'da_DK',
  },
  EE: {
    name: 'Estonia',
    currency: 'EUR',
    locale: 'et_EE',
  },
  FI: {
    name: 'Finland',
    currency: 'EUR',
    locale: 'fi_FI',
  },
  FR: {
    name: 'France',
    currency: 'EUR',
    locale: 'fr_FR',
  },
  GB: {
    name: 'United Kingdom',
    currency: 'GBP',
    locale: 'en_GB',
  },
  GR: {
    name: 'Greece',
    currency: 'EUR',
    locale: 'el_GR',
  },
  IE: {
    name: 'Ireland',
    currency: 'EUR',
    locale: 'en_IE',
  },
  IN: {
    name: 'India',
    currency: 'INR',
    locale: 'en_IN',
  },
  IT: {
    name: 'Italy',
    currency: 'EUR',
    locale: 'it_IT',
  },
  LT: {
    name: 'Lithuania',
    currency: 'EUR',
    locale: 'lt_LT',
  },
  LB: {
    name: 'Lebanon',
    currency: 'LBP',
    locale: 'ar_LB',
  },
  LU: {
    name: 'Luxembourg',
    currency: 'EUR',
    locale: 'fr_LU',
  },
  LV: {
    name: 'Latvia',
    currency: 'EUR',
    locale: 'lv_LV',
  },
  MT: {
    name: 'Malta',
    currency: 'EUR',
    locale: 'mt_MT',
  },
  NL: {
    name: 'Netherlands',
    currency: 'EUR',
    locale: 'nl_NL',
  },
  NO: {
    name: 'Norway',
    currency: 'NOK',
    locale: 'nb_NO',
  },
  PH: {
    name: 'Philippines',
    currency: 'PHP',
    locale: 'en_PH',
  },
  PL: {
    name: 'Poland',
    currency: 'PLN',
    locale: 'pl_PL',
  },
  PT: {
    name: 'Portugal',
    currency: 'EUR',
    locale: 'pt_PT',
  },
  RU: {
    name: 'Russia',
    currency: 'RUB',
    locale: 'ru_RU',
  },
  SE: {
    name: 'Sweden',
    currency: 'SEK',
    locale: 'sv_SE',
  },
  SK: {
    name: 'Slovakia',
    currency: 'EUR',
    locale: 'sk_SK',
  },
  SI: {
    name: 'Slovenia',
    currency: 'EUR',
    locale: 'sl_SI',
  },
  NZ: {
    name: 'New Zealand',
    currency: 'NZD',
    locale: 'en_NZ',
  },
  SG: {
    name: 'Singapore',
    currency: 'SGD',
    locale: 'en_SG',
  },
  TR: {
    name: 'Turkey',
    currency: 'TRY',
    locale: 'tr_TR',
  },
  UY: {
    name: 'Uruguay',
    currency: 'UYU',
    locale: 'es_UY',
  },
  CZ: {
    name: 'Czech Republic',
    currency: 'CZK',
    locale: 'cs_CZ',
  },
  JP: {
    name: 'Japan',
    currency: 'JPY',
    locale: 'ja_JP',
  },
  MX: {
    name: 'Mexico',
    currency: 'MXN',
    locale: 'es_MX',
  },
  CN: {
    name: 'China',
    currency: 'CNY',
    locale: 'zh_Hans_CN',
  },
  PA: {
    name: 'Panama',
    currency: 'PAB',
    locale: 'es_PA',
  },
  ZA: {
    name: 'South Africa',
    currency: 'ZAR',
    locale: 'en_ZA',
  },
  VN: {
    name: 'Vietnam',
    currency: 'VND',
    locale: 'vi_VN',
  },
  BO: {
    name: 'Bolivia',
    currency: 'BOB',
    locale: 'es_BO',
  },
  EG: {
    name: 'Egypt',
    currency: 'EGP',
    locale: 'ar_EG',
  },
  MY: {
    name: 'Malaysia',
    currency: 'MYR',
    locale: 'ms_MY',
  },
  IS: {
    name: 'Iceland',
    currency: 'ISK',
    locale: 'is_IS',
  },
  HR: {
    name: 'Croatia',
    currency: 'EUR',
    locale: 'hr_HR',
  },
  ME: {
    name: 'Montenegro',
    currency: 'EUR',
    locale: 'sr_ME',
  },
  UA: {
    name: 'Ukraine',
    currency: 'UAH',
    locale: 'uk_UA',
  },
  KY: {
    name: 'Cayman Islands',
    currency: 'KYD',
    locale: 'en_KY',
  },
  BA: {
    name: 'Bosnia & Herzegovina',
    currency: 'BAM',
    locale: 'hr_BA',
  },
  HK: {
    name: 'Hong Kong',
    currency: 'HKD',
    locale: 'zh_HK',
  },
  TH: {
    name: 'Thailand',
    currency: 'THB',
    locale: 'th_TH',
  },
  KE: {
    name: 'Kenya',
    currency: 'KES',
    locale: 'en_KE',
  },
  BR: {
    name: 'Brazil',
    currency: 'BRL',
    locale: 'pt_BR',
  },
  CO: {
    name: 'Colombia',
    currency: 'COP',
    locale: 'es_CO',
  },
  IL: {
    name: 'Israel',
    currency: 'ILS',
    locale: 'he_IL',
  },
  JO: {
    name: 'Jordan',
    currency: 'JOD',
    locale: 'ar_JO',
  },
  PK: {
    name: 'Pakistan',
    currency: 'PKR',
    locale: 'en_PK',
  },
  KR: {
    name: 'South Korea',
    currency: 'KRW',
    locale: 'ko_KR',
  },
  PE: {
    name: 'Peru',
    currency: 'PEN',
    locale: 'es_PE',
  },
  CH: {
    name: 'Switzerland',
    currency: 'CHF',
    locale: 'en_CH',
  },
  AE: {
    name: 'United Arab Emirates',
    currency: 'AED',
    locale: 'ar_AE',
  },
  MK: {
    name: 'Macedonia',
    currency: 'MKD',
    locale: 'mk_MK',
  },
  AD: {
    name: 'Andorra',
    currency: 'EUR',
    locale: 'ca_AD',
  },
  ID: {
    name: 'Indonesia',
    currency: 'IDR',
    locale: 'id_ID',
  },
  EC: {
    name: 'Ecuador',
    currency: 'USD',
    locale: 'es_EC',
  },
  MU: {
    name: 'Mauritius',
    currency: 'MUR',
    locale: 'en_MU',
  },
  TW: {
    name: 'Taiwan',
    currency: 'TWD',
    locale: 'zh_TW',
  },
  BH: {
    name: 'Bahrain',
    currency: 'BHD',
    locale: 'ar_BH',
  },
  SV: {
    name: 'El Salvador',
    currency: 'USD',
    locale: 'es_SV',
  },
  GE: {
    name: 'Georgia',
    currency: 'GEL',
    locale: 'ka_GE',
  },
};

const SUPPORTED_CURRENCIES: {[key: string]: {code: string; name: string | undefined}} = {};

for (const {currency} of Object.values(SUPPORTED_COUNTRIES)) {
  if (!SUPPORTED_CURRENCIES[currency]) {
    SUPPORTED_CURRENCIES[currency] = {
      code: currency,
      name: getCurrencyName(currency),
    };
  }
}

export interface CurrencyOption {
  name: string;
  code: string;
}

export const currencyOptions = Object.values(SUPPORTED_CURRENCIES).sort((a, b) => {
  // If USD, place at the top
  if (a.code === 'USD') return -1;
  if (b.code === 'USD') return 1;
  return a.code.localeCompare(b.code);
}) as CurrencyOption[];
