import {BusinessExpenseReportList} from '@compt/types/business-expenses/business-expense-report';
import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  BusinessExpense,
  BusinessExpenseListResults,
} from '@compt/types/business-expenses/business-expense';
import {BusinessExpenseStatus} from '@compt/types/business-expenses/business-expense-statuses';
import {ReviewStatistics} from '@compt/types/review-statistics';

export interface BusinessExpenseReviewPayload {
  business_expense_ids: number[];
  status: BusinessExpenseStatus;
}

interface BusinessExpenseReviewResponse {
  business_expenses: BusinessExpense[];
  status: BusinessExpenseStatus;
}

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminBusinessExpenseReportList: builder.query<
      BusinessExpenseReportList,
      {companyId?: number; params?: Record<string, unknown> | null}
    >({
      query: ({companyId, params}) => ({
        url: `business_expenses/${companyId}/admin/expense_reports`,
        params: {...params},
      }),
      providesTags: [ComptRTKTags.ADMIN_BUSINESS_EXPENSE_REPORT_LIST],
    }),
    getAdminBusinessExpenses: builder.query<
      BusinessExpenseListResults,
      {companyId?: number; reportId?: string | number}
    >({
      query: ({companyId, reportId}) => ({
        url: `/business_expenses/${companyId}/admin/${reportId}/business_expenses`,
      }),
      providesTags: [ComptRTKTags.ADMIN_BUSINESS_EXPENSE_LIST],
    }),
    getHistoricalBusinessExpenses: builder.query<
      BusinessExpenseListResults,
      {companyId?: number; params?: Record<string, unknown> | null}
    >({
      query: ({companyId, params}) => ({
        url: `/business_expenses/${companyId}/historical/business_expenses`,
        params: {...params},
      }),
      providesTags: [ComptRTKTags.HISTORICAL_BUSINESS_EXPENSE_LIST],
    }),
    bulkUpdateBusinessExpenses: builder.mutation<
      BusinessExpenseReviewResponse,
      BusinessExpenseReviewPayload
    >({
      query: (data) => {
        const {...body} = data;

        return {
          url: 'business_expenses/review_business_expenses',
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.BUSINESS_EXPENSE_REPORT,
        ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST,
        ComptRTKTags.BUSINESS_EXPENSE_LIST,
        ComptRTKTags.ADMIN_BUSINESS_EXPENSE_LIST,
        ComptRTKTags.ADMIN_BUSINESS_EXPENSE_REPORT_LIST,
      ],
    }),
    unapproveBusinessExpense: builder.mutation<
      BusinessExpenseReviewResponse,
      {business_expense: BusinessExpense}
    >({
      query: (data) => {
        const {id} = data.business_expense;
        return {
          url: `/business_expenses/unapprove_business_expenses/${id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: [
        ComptRTKTags.BUSINESS_EXPENSE_REPORT,
        ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST,
        ComptRTKTags.BUSINESS_EXPENSE_LIST,
        ComptRTKTags.ADMIN_BUSINESS_EXPENSE_LIST,
        ComptRTKTags.ADMIN_BUSINESS_EXPENSE_REPORT_LIST,
      ],
    }),
    getBusinessExpenseReviewStatistics: builder.query<
      ReviewStatistics[],
      {companyId?: number; params?: Record<string, unknown> | null}
    >({
      query: ({companyId, params}) => ({
        url: `business_expenses/${companyId}/review_statistics`,
        params: {...params},
      }),
    }),
  }),
});

export const {
  useGetAdminBusinessExpenseReportListQuery,
  useGetAdminBusinessExpensesQuery,
  useGetHistoricalBusinessExpensesQuery,
  useBulkUpdateBusinessExpensesMutation,
  useUnapproveBusinessExpenseMutation,
  useGetBusinessExpenseReviewStatisticsQuery,
} = extendedComptApiSlice;
