import {DateTime} from 'luxon';

export const formatDateString = (dateString: string) => {
  const [year, month, day] = dateString.split('-');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const isValidDate = (date: Date | null) => (date ? !isNaN(date.getTime()) : false);

export const getTomorrowDateString = () => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const year = tomorrow.getFullYear();
  const month = String(tomorrow.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(tomorrow.getDate()).padStart(2, '0');

  return `${month}-${day}-${year}`;
};

export const dateToISODateString = (date: Date | string) => {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  // getTimezoneOffset returns difference, in minutes, between the date in UTC and local timezone
  const offset = date.getTimezoneOffset();
  // getTime returns milliseconds since epoch, so need to convert offset (minutes) to milliseconds (* 60 * 1000)
  const dateWithOffset = new Date(date.getTime() - offset * 60 * 1000);
  return dateWithOffset.toISOString().split('T')[0];
};

export const getUTCDateFromString = (dateString: string | Date | undefined | null) => {
  if (!dateString) {
    return '';
  }
  // Convert to UTC because Date() assumes the string is UTC, but will apply the local timezone
  // This can cause the dates to be displayed incorrectly, so we convert it back to UTC to get
  // the date that the string actually displayed.
  const convertedDate = new Date(dateString);
  return new Date(
    convertedDate.getUTCFullYear(),
    convertedDate.getUTCMonth(),
    convertedDate.getUTCDate(),
  );
};

export const formattedDate = (
  dateString: string | Date | undefined | null,
  formatOption: DATE_FORMAT_OPTION,
) => {
  if (!dateString) {
    return '';
  }
  if (dateString instanceof Date) {
    dateString = dateString.toISOString();
  }
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return DateTime.fromISO(dateString).setZone(browserTimeZone).toFormat(formatOption);
};

export const formattedDatetime = (
  datetimeString: string | Date,
  formatOption: DATE_FORMAT_OPTION,
) => {
  const datetime = new Date(datetimeString);
  return datetime ? datetime.toLocaleDateString('en-US', DateFormats[formatOption]) : '';
};

export enum DATE_FORMAT_OPTION {
  'month dd yyyy' = 'MMM d, yyyy',
  'month dd yyyy hh:mm:ss' = 'MMM d, yyyy hh:mm:ss',
  'mm/dd/yyyy' = 'MM/dd/yyyy',
  'month d, yyyy at h:mm a ZZZZ' = "MMM d, yyyy 'at' h:mm a ZZZZ",
  'yyyy-mm-dd' = 'yyyy-MM-dd',
  'day mm/dd/yyyy at h:mma' = "EEEE, MM/dd/yyyy 'at' h:mma",
}

const DateFormats: Record<DATE_FORMAT_OPTION, Intl.DateTimeFormatOptions> = {
  'MMM d, yyyy': {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  'MMM d, yyyy hh:mm:ss': {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'shortGeneric',
  },
  'MM/dd/yyyy': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  "MMM d, yyyy 'at' h:mm a ZZZZ": {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  'yyyy-MM-dd': {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  "EEEE, MM/dd/yyyy 'at' h:mma": {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
};

export const timeAgo = (date: Date | string): string => {
  const now = DateTime.local();
  const targetDate = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);

  if (!targetDate.isValid) {
    throw new Error('Invalid date provided');
  }

  const diffMs = now.toMillis() - targetDate.toMillis();
  const diffSecs = Math.floor(diffMs / 1000);
  const diffMins = Math.floor(diffSecs / 60);
  const diffHours = Math.floor(diffMins / 60);
  const diffDays = Math.floor(diffHours / 24);
  const diffWeeks = Math.floor(diffDays / 7);

  if (diffSecs < 60) {
    return `${Math.floor(diffSecs)} seconds ago`;
  } else if (diffMins < 60) {
    return `${Math.floor(diffMins)} minute${diffMins > 1 ? 's' : ''} ago`;
  } else if (diffHours < 24) {
    return `${Math.floor(diffHours)} hour${diffHours > 1 ? 's' : ''} ago`;
  } else if (diffDays < 7) {
    return `${Math.floor(diffDays)} day${diffDays > 1 ? 's' : ''} ago`;
  } else if (diffWeeks < 4) {
    return `${Math.floor(diffWeeks)} week${Math.floor(diffWeeks) > 1 ? 's' : ''} ago`;
  } else {
    // If older than 4 weeks, format as a date string with time and timezone
    const formattedDate = targetDate.toFormat('MMM d, yyyy');
    const formattedTime = targetDate.toFormat('h:mm a ZZZZ');

    return `${formattedDate} at ${formattedTime}`;
  }
};

/**
 *
 * @param expirationDate - the date when the item is set to expire
 * @returns an object with a boolean that indicates if the date has expired or not
 *          and the formatted expiration date aka the targetDate.
 */
export const isExpiredDate = (expirationDate?: string) => {
  const targetDate = DateTime.fromISO(expirationDate || '').endOf('day');
  const now = DateTime.local();
  return {isExpired: targetDate <= now, targetDate: targetDate.toFormat('MMM d, yyyy')};
};
