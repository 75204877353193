import React from 'react';

// RTK queries
import {useGetBusinessExpenseReviewStatisticsQuery} from '@compt/app/services/api/admin-business-expenses-slice';
import {useGetBundledQueries} from '@compt/utils/queries-helper';

// Types
import {ReviewTabType} from '@compt/types/stipend-expenses/expense-review';

// Hooks and Methods
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {formatCurrency} from '@compt/utils/international-helpers';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {useFormatFilterQuery} from '@compt/pages/admin-pages/admin-review-programs/format-query-helper';
import {useDebounce} from '@uidotdev/usehooks';
// Components
import {ReviewScoreButton} from '@compt/pages/admin-pages/admin-review-programs/components/review-score-button';

export const ReviewBusinessExpenseScorecards = () => {
  const isMobile = useIsMobileView();

  const {company} = useGetBundledQueries();
  const companyId = company.data?.id;

  const formatFilterQuery = useFormatFilterQuery();
  const debouncedQueryValues = useDebounce(formatFilterQuery, 300);

  const statisticsQuery = useGetBusinessExpenseReviewStatisticsQuery(
    {
      companyId,
      params: {
        ...debouncedQueryValues,
      },
    },
    {skip: !companyId},
  );
  return (
    <div className={'flex flex-row gap-6 mb-4'}>
      {!isMobile &&
        featureEnabled(FeatureFlags.CONSOLIDATE_PROGRAMS) &&
        featureEnabled(FeatureFlags.SHOW_REVIEW_SCORECARDS) &&
        statisticsQuery &&
        statisticsQuery.data &&
        statisticsQuery.data.length > 0 &&
        statisticsQuery.data.map((data) => (
          <div
            key={data.id}
            className="flex flex-col items-start p-4 gap-3 rounded-xl w-[480px]
            border border-gray-300 bg-white flex-1"
          >
            <p className="label4 text-color-body2">{data.title}</p>
            <h3 className="heading1 text-color-heading">
              {data.currency
                ? formatCurrency(data.total_amount, data.currency, 0)
                : data.total_amount}
            </h3>
            <div>
              {data.currency ? (
                <ReviewScoreButton activeTab={ReviewTabType.SPENT} showActiveTab={true} />
              ) : (
                <ReviewScoreButton activeTab={ReviewTabType.TOTAL} showActiveTab={true} />
              )}
            </div>
          </div>
        ))}
    </div>
  );
};
