import React from 'react';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {ReviewBEReportsTableController as controller} from './business-expenses/review-business-expense-reports-table.controller';

// Types
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {BusinessExpenseReportStatus} from '@compt/types/business-expenses/business-expense-report-statuses';

// Components
import {FilterContextProvider} from '@compt/common/compt-filter-sidebar/filter-context';
import {ReviewBusinessExpenseReportsTable} from './business-expenses/review-business-expense-reports-table';
import {LeanComptTableContextProvider} from '@compt/common/lean-compt-table/lean-compt-table-context';
import {ComptPortal, PortalRoot} from '@compt/common/compt-portal/compt-portal';
import {
  BusinessExpenseReportFilter,
  ReviewBusinessExpenseReportSidebar,
} from './business-expenses/review-business-expense-reports-sidebar';
import {FilterWarningBanner} from './components/filter-banner';
import {ReviewBusinessExpenseScorecards} from '@compt/pages/admin-pages/admin-review-programs/business-expenses/review-business-expense-scorecards';

export const ReviewBusinessExpensesTab = () => {
  const FILTER_ID = 'review-business-expense-reports-table-v1.0.0';

  const navigate = useNavigate();

  const initialFilterValues = {
    [BusinessExpenseReportFilter.SEARCH]: '',
    [BusinessExpenseReportFilter.STATUS]: [],
    [BusinessExpenseReportFilter.SUBMITTED_START_DATE]: '',
    [BusinessExpenseReportFilter.SUBMITTED_END_DATE]: '',
  };

  const onActionClicked = (report: BusinessExpenseReport) => {
    navigate(`/review-programs/business-expenses/${report.id}`);
  };

  const columnDefinition = controller.getColumnDefinition(onActionClicked);

  return (
    <FilterContextProvider filterId={FILTER_ID} initialValues={initialFilterValues}>
      <ReviewBusinessExpenseScorecards />
      <FilterWarningBanner
        filterKey={BusinessExpenseReportFilter.STATUS}
        shouldShowWarning={(filterValues, reviewingCount) =>
          // Show the banner if filters are selected, not both statuses are included, and items need attention
          filterValues.length > 0 &&
          !(
            filterValues.includes(BusinessExpenseReportStatus.SUBMITTED) &&
            filterValues.includes(BusinessExpenseReportStatus.REVIEWED_BY_MANAGER)
          ) &&
          reviewingCount?.business_expenses_count > 0
        }
        messageText="expense reports"
      />
      <LeanComptTableContextProvider columnDefinition={columnDefinition} tableId={FILTER_ID}>
        <ReviewBusinessExpenseReportsTable />
      </LeanComptTableContextProvider>
      <ComptPortal portalNode={PortalRoot.RIGHT_NAV}>
        <ReviewBusinessExpenseReportSidebar />
      </ComptPortal>
    </FilterContextProvider>
  );
};
