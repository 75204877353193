import React, {useState, useEffect, useRef} from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';

// Components
import {ReviewScoreCard, ReviewCardProps} from './review-score-card';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

interface ReviewScoreCardCarouselProps {
  reviewsData: ReviewCardProps[];
  tooltipMessage?: string;
  className?: string;
  'data-testid'?: string;
}

export const ReviewScoreCardCarousel = (props: ReviewScoreCardCarouselProps) => {
  const {reviewsData, className = '', 'data-testid': testId} = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);

  const getResponsiveValues = (width: number) => {
    if (width < 500) return {cardsPerPage: 1, slideCount: 1};
    if (width < 750) return {cardsPerPage: 2, slideCount: 1};
    if (width < 1000) return {cardsPerPage: 3, slideCount: 2};
    return {cardsPerPage: 4, slideCount: 3};
  };

  const {cardsPerPage, slideCount} = getResponsiveValues(containerWidth);

  useEffect(() => {
    if (!carouselRef.current) return;
    setContainerWidth(carouselRef.current.offsetWidth);

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === carouselRef.current) {
          setContainerWidth(entry.contentRect.width);
        }
      }
    });

    resizeObserver.observe(carouselRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (reviewsData.length > 0) {
      const maxStartIndex = Math.max(0, reviewsData.length - cardsPerPage);
      if (currentIndex > maxStartIndex) {
        setCurrentIndex(maxStartIndex);
      }
    }
  }, [containerWidth, reviewsData.length, currentIndex, cardsPerPage]);

  if (!reviewsData || reviewsData.length === 0) {
    return null;
  }

  const visiblePrograms = reviewsData.slice(currentIndex, currentIndex + cardsPerPage);
  const goToPrevPage = () => {
    const newIndex = Math.max(0, currentIndex - slideCount);
    setCurrentIndex(newIndex);
  };

  const goToNextPage = () => {
    const maxStartIndex = Math.max(0, reviewsData.length - cardsPerPage);
    const newIndex = Math.min(maxStartIndex, currentIndex + slideCount);
    setCurrentIndex(newIndex);
  };

  const showLeftArrow = currentIndex > 0;
  const showRightArrow = currentIndex < reviewsData.length - cardsPerPage;

  return (
    <div className={twMerge('relative', className)} data-testid={testId} ref={carouselRef}>
      {showLeftArrow && (
        <>
          <div
            className="absolute left-0 top-0 z-10 w-10 h-full -ml-2"
            style={{
              background: 'linear-gradient(90deg, #FAFAFA 60.25%, rgba(250, 250, 250, 0.00) 100%)',
            }}
          ></div>
          <button
            onClick={goToPrevPage}
            className="absolute left-0 top-1/2 -translate-y-1/2 flex items-center
            justify-center h-10 w-10 px-[10px] rounded-full bg-white z-10 shadow-md"
            aria-label="Previous page"
          >
            <ComptSvgIcon iconName="leading-icon-left" />
          </button>
        </>
      )}

      <div className="relative">
        <div
          className={twMerge(
            'flex gap-2 overflow-hidden',
            reviewsData.length <= cardsPerPage ? 'justify-between' : '',
          )}
        >
          {visiblePrograms.map((program) => (
            <div key={`program-${program.id}`} className="flex-1">
              <ReviewScoreCard
                id={program.id}
                title={program.title}
                total_amount={program.total_amount}
                remaining_amount={program.remaining_amount}
                spent_amount={program.spent_amount}
                currency={program.currency || 'USD'}
                exchange_rates_date={program.exchange_rates_date}
                tooltipMessage={props.tooltipMessage}
              />
            </div>
          ))}
        </div>
      </div>

      {showRightArrow && (
        <>
          <div
            className="absolute right-0 top-0 z-0 w-32 lg:w-72 h-full"
            style={{
              background: 'linear-gradient(270deg, #FAFAFA 20%, rgba(250, 250, 250, 0.00) 100%)',
            }}
          ></div>
          <button
            onClick={goToNextPage}
            className="absolute right-0 top-1/2 -translate-y-1/2 flex items-center
            justify-center h-10 w-10 px-[10px] rounded-full bg-white z-10 shadow-md"
            aria-label="Next page"
          >
            <ComptSvgIcon iconName="leading-icon-right" />
          </button>
        </>
      )}
    </div>
  );
};
