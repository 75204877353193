import React, {useState} from 'react';

// Types
import {ReviewTabType} from '@compt/types/stipend-expenses/expense-review';

// Hooks and Methods
import {formatCurrency} from '@compt/utils/international-helpers';

// Components
import {ReviewScoreButton} from './review-score-button';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {Tooltip} from 'react-tooltip';

export interface ReviewCardProps {
  id: number;
  title: string;
  total_amount: number;
  remaining_amount?: number;
  spent_amount?: number;
  currency?: string;
  exchange_rates_date?: string;
  tooltipMessage?: string;
}

export const ReviewScoreCard = ({
  title,
  total_amount,
  remaining_amount,
  spent_amount,
  currency,
  exchange_rates_date,
  tooltipMessage,
}: ReviewCardProps) => {
  const [activeTab, setActiveTab] = useState<ReviewTabType>(ReviewTabType.REMAINING);

  const amountToRender =
    activeTab === ReviewTabType.REMAINING
      ? remaining_amount || 0
      : activeTab === ReviewTabType.SPENT
      ? spent_amount || 0
      : total_amount;

  return (
    <div
      className="flex flex-col items-start p-4 gap-3 flex-1 rounded-xl
      border border-gray-300 bg-white mb-4 min-w-[240px] min-h-[156px]"
    >
      <div className="min-h-[40px] w-full flex justify-between">
        <p className="label4 text-color-body2">{title}</p>
        {tooltipMessage && exchange_rates_date && (
          <div>
            <div className="ml-2">
              <ComptSvgIcon
                iconId="scorecard-tooltip"
                iconName="help-circle"
                svgProp={{width: '16px', height: '16px'}}
                className="text-gray-500 cursor-pointer"
              />
            </div>
            <Tooltip
              anchorSelect="#scorecard-tooltip"
              opacity={100}
              style={{borderRadius: '6px', maxWidth: '300px'}}
            >
              <p className="body3">{tooltipMessage}</p>
            </Tooltip>
          </div>
        )}
      </div>

      <h3 className="heading1 text-color-heading">
        {formatCurrency(amountToRender, currency || 'USD', 0)}
      </h3>
      <div>
        <ReviewScoreButton
          activeTab={ReviewTabType.REMAINING}
          setActiveTab={setActiveTab}
          showActiveTab={activeTab === ReviewTabType.REMAINING}
        />
        <ReviewScoreButton
          activeTab={ReviewTabType.SPENT}
          setActiveTab={setActiveTab}
          showActiveTab={activeTab === ReviewTabType.SPENT}
        />
        <ReviewScoreButton
          activeTab={ReviewTabType.TOTAL}
          setActiveTab={setActiveTab}
          showActiveTab={activeTab === ReviewTabType.TOTAL}
        />
      </div>
    </div>
  );
};
